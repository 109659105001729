<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        target-components-header
        div(class="select-thank-you")
          div(@click="onSelectAll()" class="select-all") 全て選択
          div
            div ターゲット名: {{ data.name }}

        div(class="select-thank-you-title")
          div(class="column rank")
            div 順位
          div(class="column account-name")
            div アカウント名
          div(class="column")
            div 累計ギフトポイント

        div(v-for="(item, index) in list" class="select-thank-you-item")
          input(type="checkbox" v-model="list[index].selected" class="thank-you-check")
          div(@click="onAvatarClick(item.profile.id)")
            <pan-thumb :avatar="item.profile.avatar_store_path" :attribute="item.profile.attribute" :current_title="item.viewer_info.current_title" />
          div(class="thank-you-data")
            div {{ index + 1 }} 位
          div(class="thank-you-data")
            div(class="account-name") {{ item.profile.account_name }}
          div(class="thank-you-data")
            div P{{ item.total_points.toLocaleString() }}

      div(class="content-right")
        component-profile-index(v-if="ui.profile" :profile_id="profile_id" v-on:profileclose="profileClose()")
        div(v-if="!ui.profile && !ui.finished")
          div(class="pink-label") お礼を送る
          div(class="right-content-border")
            div メッセージ入力欄
              span(class="caution-t") *
            textarea(v-model="message" class="input-g-w-border")
            div(class="thank-you-button")
              input#fileUpload(type="file", v-show="false", @change="onUpload")
              img(v-show="attachment_store_path" :src="$store.state.system.config.storage_addr_list.public + attachment_store_path")
              button(@click="chooseFile()" class="pink-button") ファイルを選択
            div(class="caution-t") ※推奨サイズ：3MB以下（超えている場合は圧縮されます）
            div(class="caution-t") ※画像データ（.jpg/.png）のみ送信可能です
            br
            div(class="caution-t") ※＊は必須項目です
            component-error(:errors="errors" v-if="errors.length")
            div(class="thank-you-button")
              button(@click="onSend()" class="pink-button") 送信する
        div(v-if="!ui.profile && ui.finished")
          div(class="pink-label") お礼を送る
          div(class="right-content-border")
            button(@click="onClose()" class="close-button") <i class="fas fa-times"></i>
            div 送信しました。
    component-footer-index
</template>

<style src="./Gift.scss" lang="scss" scoped>
</style>

<script>
import TargetComponentsHeader from "../components/Header";
import { getTarget, getTargetRankList, uploadFile, sendThanksEmail } from '@/api/target'
import PanThumb from '@/components/PanThumb'
import Compressor from 'compressorjs';
import { isEnableFileName } from '@/utils/inputLimit';

export default {
  name: "SettingTargetHistoryGift",
  components: {
    TargetComponentsHeader,
    PanThumb
  },
  data() {
    return {
      profile_id: -1,
      ui: {
        finished: false,
        profile: false,
      },
      target_id: -1,
      data: {
        target_id: undefined,
        name: undefined,
        target_point: undefined,
        content: undefined,
        banner_store_path: undefined,

        current_point: undefined,
      },
      list: undefined,
      message: undefined,
      attachment_store_path: undefined,
      attachment_name: undefined,
      file:undefined,
      errors: [],
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    this.target_id = this.$route.params.target_id;
    const ret1 = await getTarget(this.target_id);
    if (ret1.data) {
      this.data.target_id = ret1.data.id;
      this.data.name = ret1.data.name;
      this.data.target_point = ret1.data.target_point;
      this.data.content = ret1.data.content;
      this.data.banner_store_path = ret1.data.banner_store_path;
      this.data.current_point = ret1.data.current_point;

      const ret2 = await getTargetRankList(this.data.target_id);
      this.list = ret2.data.map(v => {
        v.selected = false;
        return v;
      });
    }
  },
  updated() {},
  destroyed() {},
  methods: {
    onBack() {
      this.$router.go(-1)
    },
    onSelectAll() {
      this.list.map(v => {
        v.selected = true;
        return v;
      })
    },
    async onSend() {
      const form = {
        target_id: this.target_id,
        viewer_id_list: this.list.filter(v => v.selected).map(v => v.viewer_info.viewer_id),
        message: this.message,
        attachment_store_path: this.attachment_store_path,
        attachment_name: this.attachment_name
      };
      if (!this.checkValidation(form)) { return }

      await sendThanksEmail(form);
      this.ui.finished = true;
    },
    checkValidation(data) {
      this.errors = [];
      const errorStrings = [];

      if (!data.message) {
        errorStrings.push("※[メッセージ]を入力してください");
      }
      if (data.viewer_id_list.length === 0) {
        errorStrings.push(this.$i18n.t('validation.required', { attribute: '送信先の選択' } ));
      }
      // ファイルは必須ではないのであったら調べる
      if (data.attachment_name) {
        if (!data.attachment_store_path) {
          errorStrings.push(this.$i18n.t('validation.required', { attribute: 'ファイル' } ));
        }
        if (!isEnableFileName(data.attachment_name) ) {
          errorStrings.push("※ファイル形式が正しくありません。.jpgもしくは.pngのデータを選択してください");
        }
      }

      if (errorStrings.length) {
        this.errors = errorStrings;
        return false;
      }

      return true;
    },
    onClose() {
      this.ui.finished = false;
    },
    onAvatarClick(profile_id) {
      this.ui.profile = false;

      this.$nextTick(() => {
        this.profile_id = profile_id;
        this.ui.profile = true;
      })
    },
    profileClose() {
      this.ui.profile = false;
    },

    chooseFile() {
      document.getElementById("fileUpload").click();
    },
    async onUpload(event) {
      let _this = this;
      this.file = event.target.files[0];
      this.attachment_name = event.target.files[0].name
      if (!this.file) {
        return;
      }
      let imagSize = this.file.size;
      if(imagSize < 1024 * 1024 * 3) {
        var formData = new FormData();
        formData.append("file", event.target.files[0]);

        try {
          const { data } = await uploadFile(formData);
          _this.attachment_store_path = data.store_path;
          _this.attachment_name = event.target.files[0].name
        } catch (e) {
          _this.$alert('アップロードに失敗しました。不正なファイル形式です。')
        }

      } else {
        new Compressor(this.file, {
        convertSize: 3000000, // 3MB
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        async success(result) {
          var formData = new FormData();
          formData.append("file", result);
          if (formData) {
            try {
              const { data } = await uploadFile(formData);
              _this.attachment_store_path = data.store_path;
              _this.attachment_name = event.target.files[0].name
            } catch(e) {
              _this.$alert('アップロードに失敗しました。不正なファイル形式です。')
            }
          }
        },
        error(err) {
          console.log(err.message);
        }
        })
      }
    },
    avatarImageUrl(url){
      return url
    }
  }
};
</script>
